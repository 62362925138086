@tailwind base;

@tailwind components;

@tailwind utilities;


.modal__backdrop {
	position: fixed;
    background-color: #00000075;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    pointer-events: none;
    color: #000;
}

.modal__container {
	background: #fff;
	border-radius: 5px;
	max-width: 100%;
	margin: 50px auto;
	padding: 15px;
	width: 560px;
	pointer-events: auto;
}

.modal__title {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 25px;
}

.network-item,
.node-item,
.edit-item-title,
.turn-node-btn,
.contract-item {
	cursor: pointer;
}

.network-item:hover,
.node-item:hover,
.edit-item-title:hover,
.turn-node-btn:hover,
.contract-item:hover {
	background-color: #f1f1f1;
}

span[type="number"] {
	background-color: unset;
	border: none;
	padding: unset;
	font-size: unset;
	line-height: unset;
}
